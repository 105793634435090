<template>
  <div class="main-container">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form class="search-form" ref="search-form" :model="searchForm" label-width="100px">
          <el-form-item label="操作账号">
            <el-input v-model="searchForm.opAcc"></el-input>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker v-model="searchForm.opTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="search-btn" @click="queryUserList">生成</el-button>
            <el-button class="reset-btn" @click="resetBtn">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 图表区域start -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div>
          <div class="chartTitle">模块访问统计</div>
          <div id="moduleChartArea" style="height: 500px; width: 100%"></div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div>
          <div class="chartTitle">事件结果统计</div>
          <div id="resultChartArea" style="height: 500px; width: 100%"></div>
        </div>
      </el-col>
    </el-row>
    <!-- 图表区域end -->
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getModuleChart, getResultChart } from '@/service/api/audit/statistic.js'
export default {
  name: 'ChartStatistic',

  data() {
    return {
      searchForm: {
        opAcc: '',
        opTime: ''
      },
      moduleData: [],
      resultData: []
    }
  },
  mounted() {
    this.initChartData({})
  },
  methods: {
    queryUserList() {
      const data = {
        userName: this.searchForm.opAcc,
        startTime: this.searchForm.opTime && this.searchForm.opTime[0],
        endTime: this.searchForm.opTime && this.searchForm.opTime[1]
      }
      this.initChartData(data)
    },
    resetBtn() {
      this.searchForm = {
        opAcc: '',
        opTime: ''
      }
      this.initChartData({})
    },
    async initChartData(data) {
      await Promise.all([this.getModuleChartData(data), this.getResultChartData(data)])
      this.drawChart()
    },
    getModuleChartData(data) {
      return new Promise(resolve => {
        getModuleChart(data).then(res => {
          if (res.data.status === 200) {
            this.moduleData = res.data.data.map(e => ({ name: e.NAME, value: e.COUNT }))
            resolve()
          }
        })
      })
    },
    getResultChartData(data) {
      return new Promise(resolve => {
        getResultChart(data).then(res => {
          if (res.data.status === 200) {
            this.resultData = res.data.data.map(e => ({ name: e.NAME, value: e.COUNT }))
            resolve()
          }
        })
      })
    },
    drawChart() {
      this.drawModuleChart()
      this.drawResultChart()
    },
    drawModuleChart() {
      const chartDom = document.getElementById('moduleChartArea')
      const myChart = echarts.init(chartDom)
      let option = null

      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom'
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            data: this.moduleData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    drawResultChart() {
      const chartDom = document.getElementById('resultChartArea')
      const myChart = echarts.init(chartDom)
      let option = null

      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom'
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            data: this.resultData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~$assets/css/common/search-area.scss';
@import '~$assets/css/common/table-area.scss';
.main-container {
  width: 100%;
  box-sizing: border-box;
  .search-area {
    margin: 0;
    padding: 0;
  }
  .table-area {
    margin: 0;
    padding: 0;
  }
  ::v-deep.el-row {
    .chartTitle {
      border-left: 6px solid #409eff;
      padding-left: 10px;
    }
  }
}
</style>
