import axiosApi from '@/service/modules/axios-api'
import { AUDIT_SERVICE_PREFIX } from '@/service'
// 获取模块访问统计表数据
export function getModuleChart(data) {
  return axiosApi.post(AUDIT_SERVICE_PREFIX + '/auditLog/moduleStatistics', data)
}
// 获取事件结果统计表数据
export function getResultChart(data) {
  return axiosApi.post(AUDIT_SERVICE_PREFIX + '/auditLog/statusStatistics', data)
}
