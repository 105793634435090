<template>
  <div class="most-container">
    <el-tabs type="border-card" v-model="tabName">
      <el-tab-pane label="审计图表统计" name="first">
        <chart-statistic/>
      </el-tab-pane>
      <el-tab-pane label="审计列表统计" name="second">
        <table-statistic/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ChartStatistic from '@/views/manage-page/audit/pages/statistic/ChartStatistic'
import TableStatistic from '@/views/manage-page/audit/pages/statistic/TableStatistic'
export default {
  name: 'AuditStatistic',
  components: { ChartStatistic, TableStatistic },
  data() {
    return {
      tabName: 'first',
      searchForm: {
        sysModule: '',
        opUser: '',
        opType: '',
        reqType: '',
        opStatus: '',
        opTime: []
      },
      tableLoading: false,
      tableList: []
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.most-container{
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
</style>
